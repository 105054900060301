<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/Page-header";
import Multiselect from "vue-multiselect";
import DatePicker from "vue2-datepicker";

/**
 * Starter component
 */
export default {
  head() {
    return {
      title: `${this.title} | Minton - Nuxtjs Responsive Admin Dashboard Template`,
    };
  },
  data() {
    return {
      title: "旺幣查詢",
      items: [
        {
          text: "用戶/等級任務管理",
          href: "/usermanagement",
        },
        {
          text: "旺幣查詢",
          active: true,
        }
      ],
      wancoinInfo: {
        totalPublishedCount: "",
        todayPublishedCount: "",
        montyPublishedCount: "",
        totalUsedCount: "",
        totalEfficientCount: ""
      },

      tableData: [],
      totalRows: 1,
      currentPage: 1,
      perPage: 25,
      min: null,
      max: null,
      filter: null,
      filterOn: [],
      sortDesc: false,
      fields: [
        {
          key: "id",
          label: "用戶編號",
          sortable: false,
          class: 'width-auto center'
        },
        {
          key: "user_name",
          label: "姓名",
          sortable: false,
          class: 'width-auto center'
        },
        {
          key: "wancoin",
          label: "持有旺幣數量",
          sortable: false,
          class: 'width-auto center'
        },
        {
          key: "status",
          label: "帳戶狀態",
          sortable: false,
          class: 'width-auto center'
        },
        {
          key: "last_change_at",
          label: "最近一次兌換時間",
          sortable: false,
          class: 'width-auto center'
        },
        {
          key: "action",
          label: "",
          sortable: false,
          stickyColumn: true,
          class: 'width-auto center'
        }
      ],
    };
  },
  computed:{
    rows() {
      return this.tableData.length;
    },
  },
  mounted() {
    this.fetchWancoinInfo()
    this.reloadTypePageList()
  },
  methods: {
    fetchWancoinInfo() {
      const vm = this
      this.$root.apis.getWancoinInfo( 
        function (_response) {
          const result = _response.body.data
          vm.wancoinInfo.totalPublishedCount = result?.total_published_count !== '' ? `${result?.total_published_count} 枚` : '-'
          vm.wancoinInfo.todayPublishedCount = result?.today_published_count !== '' ? `${result?.today_published_count} 枚` : '-'
          vm.wancoinInfo.montyPublishedCount = result?.monty_published_count !== '' ? `${result?.monty_published_count} 枚` : '-'
          vm.wancoinInfo.totalUsedCount = result?.total_used_count !== '' ? `${result?.total_used_count} 枚` : '-'
          vm.wancoinInfo.totalEfficientCount = result?.total_efficient_count !== '' ? `${result?.total_efficient_count} 枚` : '-'
        },
        function (_error) {
          console.error(_error)
          vm.$root.common.showErrorDialog(_error.body.data)
        }
      )
    },
    reloadTypePageList () {
      if((!this.min && this.max) || (this.min && !this.max)) {
        this.$root.common.showErrorDialog('區間填寫不完整!')
        return
      }
      this.$refs.searchTable.refresh()
    },
    searchList (_table, _callback) {
      const vm = this

      const searchParam = {
        page: this.currentPage || 1,
        min: this.min || null,
        max: this.max || null
      }
      this.$root.apis.getWancoinMember(searchParam, 
        function (_response) {
          let response = _response.body.data
          vm.totalRows = response.total
          vm.currentPage = response.page
          vm.perPage = response.per_page
          if (typeof _callback === 'function') {
            vm.tableData = response.items
            _callback(vm.tableData)
          }
        },
        function (_error) {
          console.error(_error)
          vm.$root.common.showErrorDialog(_error.body.data)
          if (typeof _callback === 'function') {
            _callback([])
          }
        })
    },
    onFiltered(filteredItems) {
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    onClickDetail(_userId) {
      this.$router.push({ path: `/usermanagement/setting/${_userId}`})
    },
    onExport(){
      window.open(`${process.env.VUE_APP_BACKEND_BASE_URL}/export/member-list?min=${this.min}&max=${this.max}`,'_blank')
    }
  },
  components: {
    Layout,
    PageHeader,
    Multiselect,
    DatePicker,
  },
};
</script>

<template lang="pug">
Layout
  PageHeader(:title="title", :items="items")
  .row
    .col-lg-12
      //- 搜尋bar
      b-card.mb-1(no-body)
        .card-body
          form.form-horizontal(role="form")
            .row
              .col-lg-4
                .form-group.mb-3
                  label 目前已發出旺幣數量
                  br
                  input.form-control(
                    v-model="wancoinInfo.totalPublishedCount", 
                    type="text", 
                    placeholder="目前已發出旺幣數量"
                    disabled
                  )
              .col-lg-4
                .form-group.mb-3
                  label 本日已發出旺幣數量
                  br
                  input.form-control(
                    v-model="wancoinInfo.todayPublishedCount",
                    type="text",
                    placeholder="本日已發出旺幣數量"
                    disabled
                  )
              .col-lg-4
                .form-group.mb-3
                  label 本月已發出旺幣數量
                  br
                  input.form-control(
                    v-model="wancoinInfo.montyPublishedCount",
                    type="text",
                    laceholder="本月已發出旺幣數量"
                    disabled
                  )
            .row
              .col-lg-4
                .form-group.mb-3
                  label 目前使用出旺幣數量
                  br
                  input.form-control(
                    v-model="wancoinInfo.totalUsedCount",
                    type="text",
                    placeholder="目前使用出旺幣數量"
                    disabled
                  )
              .col-lg-4
                .form-group.mb-3
                  label 目前尚有效之旺幣數量
                  br
                  input.form-control(
                    v-model="wancoinInfo.totalEfficientCount",
                    type="text",
                    placeholder="目前尚有效之旺幣數量"
                    disabled
                  )
      b-card.mb-1(no-body)
        .card-body.pl-0.pr-0      
          b-card-body
            form.form-horizontal(role="form")
              .row.form-btn
                .col-lg-4
                  .form-group
                    label.mb-1 篩選尚持有旺幣之帳戶名單
                    br
                    .row
                      .col-6.d-flex.justify-content-between.align-items-center
                        input.form-control(v-model="min", type="number")
                        span.ml-3.mt-1 ～
                      .col-6
                        input.form-control(v-model="max", type="number")
                .col-lg-7
                  b-button.wanbtn.width-lg(variant="primary" @click="reloadTypePageList") 查詢
      //- 表格
      .card
        .card-body
          .header-row.mb-3
            .header-title 查詢清單
            p.mr-3.mt-2 符合條件資料共計有 {{ totalRows }} 筆資料
            b-button.width-lg(variant="primary" @click="onExport") 匯出
          //- Table
          .row
            .col-12
              b-table(
                head-variant="light"
                ref="searchTable"
                :items="searchList", 
                :fields="fields", 
                responsive, 
                :per-page="perPage", 
                :current-page="currentPage", 
                :sort-desc.sync="sortDesc", 
                :filter="filter", 
                :filter-included-fields="filterOn", 
                @filtered="onFiltered")
                
                template(v-slot:cell(status)="data")
                  b-button(
                    v-if="data.item.status === 0",
                    variant="warning",
                    size="sm"
                  ) 停權
                  b-button(
                    v-else-if="data.item.status === 1",
                    variant="success",
                    size="sm"
                  ) 正常
                  b-button(
                    v-else-if="data.item.status === 3", 
                    variant="danger", 
                    size="sm"
                  ) 封鎖
                  span(v-else) -

                template(v-slot:cell(action)="data")
                  b-button.width-lg(variant="primary" @click="onClickDetail(data.item.id)") 查看
          
          .row.mb-md-2
            .col-12.d-flex.justify-content-start
              div(class="dataTables_paginate paging_simple_numbers float-right")
                ul.pagination.pagination-rounded.mb-0
                  b-pagination(v-model="currentPage", :total-rows="totalRows", :per-page="perPage")
</template>
<style lang="sass" scoped>
.title-box
  display: flex
  justify-content: space-between
  align-items: center
  .addbtn
    display: flex
    justify-content: center
    align-items: center
    .ri-file-add-fill
      font-size: 1.4rem
      line-height: 1.5rem 
.card-header
  background: #fff
.label-select
  border: none
  padding: 0
  height: auto
  line-height: 1
  font-weight: 600
.header-row
  display: flex
  align-items: center
  .header-title
    margin: 0
    flex: 1
.form-btn
  display: flex
  justify-content: flex-start
  align-items: center
  .wanbtn
    margin-top: 10px
::v-deep  .b-table-sticky-column:last-child
  right: 0
  position: sticky !important
  background: #f1f5f7
::v-deep td
  &.b-table-sticky-column:last-child
    background: #fff !important
::v-deep .width-auto
  min-width: 7rem
  white-space: nowrap
  vertical-align: middle
  &.center
    text-align: center
</style>
